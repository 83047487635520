import React from 'react'
import TitleCard from '../components/TitleCard.js'
import {Helmet} from 'react-helmet'

const IndexPage = () => (
  <div>
    <TitleCard />
    <Helmet>
        <title>Under New Suns</title>
    </Helmet>
  </div>
)

export default IndexPage
