import React from 'react'
import Link from 'gatsby-link'
import Logo from '../images/thenewsun-b.png'
import styles from './titlecard.module.css'
import {Helmet} from 'react-helmet'

const TitleCard = () => 
    <div className={styles.div}>
        <Helmet>
            <title>Under New Suns</title>
        </Helmet>
        <div className={styles.imagecontainer}>
           <img src={Logo} className={styles.img}/>
        </div>
        <h1 className={styles.h1}>UNDER NEW SUNS</h1>
        <div className={styles.underscore}></div>
        <ul className={styles.ul}>
            <li className={styles.li}><Link to="content">Content</Link></li>
            <li className={styles.li}><Link to="about"> About</Link></li>
            <li className={styles.li}><Link to="contact">Contact</Link></li>
            <li className={styles.li}><Link to="blog">Blog</Link></li>
        </ul>
    </div>

export default TitleCard
